<template>
  <div class="purchase-section">
    <v-container class="container-custom purchase">
      <h1>WATCH COURSES VIDEOS ON DEMAND</h1>
      <div class="demand-learning">
        <p>
          Whether you're taking an online class or can't attend a live session
          due to time constraints or work
        </p>
        <p>
          commitments, don't worry—Neco Job has you covered with OnDemand
          videos. You can learn at your
        </p>
        <p>convenience with just a tap.</p>
        <p>
          Stay on top of the skills you need. Courses as low as
          <strong>Rs. 7500</strong> for unlimited video library access.
        </p>
      </div>
      <button class="btn purchase-bth" @click="dialog = true">GET NOW</button>
      <div class="formjoin absModal" v-if="dialog">
        <div class="insideBox">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              background-color: #1976d2;
              padding: 10px;
              border-radius: 10px 10px 0 0;
            "
          >
            <h6 style="font-size: 20px; color: #fff">Order summary</h6>

            <v-icon
              @click="dialog = false"
              style="color: #fff; position: relative; top: -2px"
              >mdi-close</v-icon
            >
          </div>

          <div
            style="
              padding: 15px 20px;
              gap: 20px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
            "
          >
            <div style="width: 80%">
              <h6 style="font-size: 16px; padding-bottom: 5px">Subcriptions</h6>
              <p>Video Membership </p>
            </div>
            <div style="width: 20%">
              <h6 style="font-size: 16px; padding-bottom: 5px">Price</h6>
              <p>NRP {{ allTraining.results.price }}</p>
            </div>
          </div>
          <ul class="paymentList">
            <li
              v-for="(data, index) in payFroms"
              :key="index"
              @click="payFrom = data.value"
              :class="payFrom == data.value ? 'active' : ''"
            >
              <img
                :src="data.image"
                alt=""
                style="height: 40px; object-fit: contain; width: 100px"
              />
            </li>
          </ul>
          <v-form
            ref="formUpcoming"
            v-model="valid"
            lazy-validation
            class=""
            style="padding: 0 20px 20px 20px"
          >
            <v-text-field
              v-model="fullNameUp"
              outlined
              label="Full Name"
              :rules="[(v) => !!v || 'Name is required']"
            ></v-text-field>
            <v-text-field
              v-model="phoneUp"
              outlined
              label="Phone"
              :rules="[
                (v) => !!v || 'Phone is required',
                (v) =>
                  (v && v.length <= 10) ||
                  'Phone must be less than 10 characters',
              ]"
            ></v-text-field>
            <v-text-field
              v-model="emailUp"
              outlined
              label="Email"
              :rules="[
                ((v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
              ]"
            ></v-text-field>

            <v-btn
              type="button"
              :loading="submitLoading"
              class="primary"
              @click="submitFormUpcoming"
              style=""
              >Proceed to Payment (NRP {{ allTraining.results.price }})</v-btn
            >
          </v-form>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaymentFormVideo",
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
    dialog: false,
    valid: true,
    fullNameUp: "",
    emailUp: "",
    phoneUp: "",
    submitLoading: false,
    payFroms: [
      {
        title: "IME Payment",
        value: "paymentprocessime",
        image: require("@/assets/imepay.jpg"),
      },
      {
        title: "Nepal Payment",
        value: "paymentprocess",
        image: require("@/assets/nepalpay.jpg"),
      },
    ],
    payFrom: "paymentprocess",
  }),
  methods: {
    submitFormUpcoming() {
      if (this.$refs.formUpcoming.validate()) {
        this.$router.push(
          `/${this.payFrom}?fullName=${this.fullNameUp}&email=${this.emailUp}&phone=${this.phoneUp}&subject=Video Subscription&trainingId=${this.allTraining.results._id}&slug=subscriptions&amount=${this.allTraining.results.price}`
        );
      }
    },
  },
  computed: {
    ...mapGetters(["allTraining"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: 30px;
  font-weight: 800;
  line-height: 3;
}
.absModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}
.insideBox {
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.paymentList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  li {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    border: 1px solid #1876d2;
  }
}
@media screen and (max-width: 600px) {
  .insideBox {
    width: 95%;
  }
  .coursecard {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .btncta,
  .btncta1 {
    font-size: 12px !important;
  }
}
.purchase-section {
  background: #efefef;
  padding: 70px 0;
  .purchase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 35px;
    }
    .demand-learning {
      padding: 11px 0;
      p {
        text-align: center;
        font-size: 18px;
        line-height: 1.9;
      }
    }
    .purchase-bth {
      background: #ff9f00;
      padding: 10px 35px;
      margin-top: 10px;
      border-radius: 5px;
      color: white;
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    line-height: 1.3;
    margin: 0 0 18px 0;
  }
  .boxinside {
    padding: 25px 0;
  }
  .slider {
    padding: 30px 8px 10px 8px;
  }
  .video-section {
    padding: 30px 0;
  }
  .purchase-section {
    h1 {
      font-size: 25px !important;
    }
    .demand-learning {
      p {
        font-size: 14px !important;
      }
    }
  }
  .btn-filter {
    display: none;
  }
  .video-title {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
}
</style>
