<template>
  <section
    class="hero-banner"
    :style="{ backgroundImage: `url(${bannerImage})` }"
  >
    <v-container class="container-custom">
      <v-row>
        <v-col class="banner-text">
          <p class="overline course-title">Online Course</p>
          <h1 class="display-1">
            <span>Find The Best Online</span>
            <span>Course & Learn</span>
          </h1>
          <div class="statistics-overview">
            <p>Over 2000+ students</p>
            <p>Over 50+ Videos</p>
            <p>Learn Anything Anytime</p>
          </div>

          <v-row class="media-field">
            <v-col md="5" cols="9">
              <form @submit.prevent="onSubmit" class="search-form">
                <input
                  v-model="searchQuery"
                  type="search"
                  placeholder="Enter Keyword or Course"
                  aria-label="Search"
                  class="search-input"
                />
                <button type="submit" class="search-button">Search</button>
              </form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "HeroBanner",
  data() {
    return {
      searchQuery: "",
      isFormValid: true,
      bannerImage: require("@/assets/videopageBanner.png"),
    };
  },
  methods: {
    onSubmit() {
      if (this.searchQuery) {
        this.$router.push(
          `/searchvideo?search=${encodeURIComponent(this.searchQuery)}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.hero-banner {
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  color: white;
  height: 680px;

  .banner-text {
    margin-top: 40px;
    .course-title {
      font-size: 20px !important;
    }
  }
  .display-1 {
    display: flex;
    flex-direction: column;
    font-size: 50px !important;
    font-weight: bold;
    margin: 5px 0;
    span {
      line-height: 1;
    }
  }
  @media (min-width: 960px) {
    padding: 120px 0;
  }

  .statistics-overview {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 30px 0;
  }

  .v-form {
    max-width: 500px;
  }
}
.search-form {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 30px;
  border-radius: 50px;

  .search-button {
    background: #ec3e32;
    padding: 9px 40px;
    color: white;
    font-weight: bold;
    border-radius: 50px;
  }
  .search-input {
    width: 100%;
    outline: none;
  }
}
@media screen and (max-width: 600px) {
  .hero-banner {
    height: 500px;
    background-position: center;

    .banner-text {
      .course-title {
        font-size: 20px !important;
        text-align: center;
      }
    }
  }
  .display-1 {
    align-items: center;

    span {
      font-size: 35px !important;
      margin-top: 12px;
      line-height: 0.7 !important;
    }
  }
  .statistics-overview {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10;
    margin: 30px 0 !important;
    font-weight: bold;
    line-height: 0.4;
  }

  .media-field {
    display: flex;
    justify-content: center;
    align-items: center;

    .search-button {
      padding: 5px 15px;
      font-size: 12px;
    }
    .search-input {
      width: 100%;
      font-size: 12px;
    }
  }
}
</style>
