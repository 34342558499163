<template>
  <section>
    <VideoBanner />

    <v-container class="container-custom">
      <div class="boxinside">
        <h1>Top categories</h1>
        <v-row v-if="loading">
          <v-col v-for="n in 3" :key="n">
            <v-skeleton-loader
              max-width="420"
              type="image"
              height="200"
            ></v-skeleton-loader>
            <v-skeleton-loader
              max-width="420"
              type="image"
              height="100"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="(item, index) in allCategory.results.list"
            :key="index"
          >
            <router-link :to="`/searchvideo?search=${item.title}`">
              <div
                class="about3col"
                :style="{
                  backgroundImage: `url(https://app.necojobs.com.np${item.imageUrl})`,
                }"
              >
                <div
                  class="overlay"
                  :style="{ background: getColor(index) }"
                ></div>
                <div class="category-list">
                  <h4>{{ item.title }}</h4>
                  <p>{{ item.courses }} Courses</p>
                </div>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div class="slider">
      <v-container class="container-custom belowfold">
        <h1>Trusted by over 1000+ companies</h1>
        <v-row v-if="loading">
          <v-col v-for="n in 8" :key="n">
            <v-skeleton-loader
              max-width="150"
              type="image"
              height="100"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <slick
          ref="slick"
          :options="slickOptions"
          v-if="certifications.length > 0 && !loading"
        >
          <div
            v-for="(data, index) in certifications"
            :key="index"
            class="logoscompany"
          >
            <img :src="data.image" class="companyimg" alt="" />
          </div>
        </slick>
      </v-container>
    </div>

    <v-container class="container-custom">
      <div class="video-section">
        <div class="video-title">
          <h1>A broad selection of courses</h1>
          <div class="mr-btm">
            <router-link :to="`/searchvideo`"
              ><v-btn class="btn-filter">View All</v-btn></router-link
            >
          </div>
        </div>
        <v-row v-if="loading">
          <v-col v-for="n in 4" :key="n">
            <v-skeleton-loader
              max-width="420"
              type="image"
              height="200"
            ></v-skeleton-loader>
            <v-skeleton-loader
              max-width="420"
              type="image"
              height="100"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col
            cols="12"
            sm="6"
            md="3"
            v-for="(item, index) in allSubcategory.results.list"
            :key="index"
          >
            <div class="video-list">
              <router-link
                :to="{ name: 'VideoPage', params: { id: item._id } }"
              >
                <img
                  :src="'https://app.necojobs.com.np' + item.imageUrl"
                  alt=""
                  class="videoImage"
              /></router-link>

              <h4>{{ item.title }}</h4>
              <p>{{ item.author }}</p>
              <small>{{ item.videos }} Videos</small>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

      <PaymentFormVideo />
      <!-- <v-container class="container-custom purchase">
        <h1>WATCH COURSES VIDEOS ON DEMAND</h1>
        <div class="demand-learning">
          <p>
            Whether you're taking an online class or can't attend a live session
            due to time constraints or work
          </p>
          <p>
            commitments, don't worry—Neco Job has you covered with OnDemand
            videos. You can learn at your
          </p>
          <p>convenience with just a tap.</p>
          <p>
            Stay on top of the skills you need. Courses as low as
            <strong>Rs. 7500</strong> for unlimited video library access.
          </p>
        </div>
        <button class="btn purchase-bth" @click="dialog = true">GET NOW</button>
        <div class="formjoin absModal" v-if="dialog">
          <div class="insideBox">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <h6>Join us for</h6>
              <v-icon
                @click="dialog = false"
                style="position: relative; top: -15px"
                >mdi-close</v-icon
              >
            </div>

            <h5>Video Subscription</h5>
            <ul class="paymentList">
              <li
                v-for="(data, index) in payFroms"
                :key="index"
                @click="payFrom = data.value"
                :class="payFrom == data.value ? 'active' : ''"
              >
                {{ data.title }}
              </li>
            </ul>
            <v-form ref="formUpcoming" v-model="valid" lazy-validation class="">
              <v-text-field
                v-model="fullNameUp"
                outlined
                label="Full Name"
                :rules="[(v) => !!v || 'Name is required']"
              ></v-text-field>
              <v-text-field
                v-model="emailUp"
                outlined
                label="Email"
                :rules="[
                  ((v) => !!v || 'E-mail is required',
                  (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
                ]"
              ></v-text-field>
              <v-text-field
                v-model="phoneUp"
                outlined
                label="Phone"
                :rules="[
                  (v) => !!v || 'Phone is required',
                  (v) =>
                    (v && v.length <= 10) ||
                    'Phone must be less than 10 characters',
                ]"
              ></v-text-field>
              <p>
                A booking fee of
                <strong>Rs. 7500 </strong>
                is required to secure your seat. Please settle the remaining
                amount at the beginning of the class.
              </p>
              <v-btn
                type="button"
                :loading="submitLoading"
                class="primary mt-3"
                @click="submitFormUpcoming"
                style=""
                >Proceed to Payment</v-btn
              >
            </v-form>
          </div>
        </div>
      </v-container> -->

  </section>
</template>

<script>
import VideoBanner from "@/components/frontend/VideoBanner";
import { mapActions, mapGetters } from "vuex";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "vue-slick";
import PaymentFormVideo from "@/components/PaymentFormVideo";
export default {
  components: {
    VideoBanner,
    Slick,
    PaymentFormVideo
  },
  data() {
    return {
      dialog: false,
      certifications: [],
      slickOptions: {
        slidesToShow: 8,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
        ],
      },
      slickOptions1: {
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 8,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,

        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
        ],
      },
      status: ["Active", "Inactive"],
      title: "",
      adsposition: "",
      valid: true,
      limit: 100,
      skip: 1,
      color: [
        "lightblue",
        "Wheat",
        "pink",
        "lightgray",
        "lightgreen",
        "purple",
      ],

      fullNameUp: "",
      emailUp: "",
      phoneUp: "",
      loading: true,
      payFroms: [
        {
          title: "IME Payment",
          value: "paymentprocessime",
        },
        {
          title: "Nepal Payment",
          value: "paymentprocess",
        },
      ],
      payFrom: "paymentprocess",
    };
  },
  methods: {
    ...mapActions(["getAssets", "fetchCategory", "fetchSubCategory","getSingleTrainingFront"]),
    getColor(index) {
      return this.color[index % this.color.length];
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    nextPage(data) {
      this.fetchCategory({
        limit: this.limit,
        skip: data,
      });
    },
    prevPage(data) {
      this.fetchCategory({
        limit: this.limit,
        skip: data,
      });
    },
    submitFormUpcoming() {
      if (this.$refs.formUpcoming.validate()) {
        this.$router.push(
          `/${this.payFrom}?fullName=${this.fullNameUp}&email=${this.emailUp}&phone=${this.phoneUp}&subject=Video Subscription&trainingId=6690307a232e6463943c364c&slug=subscriptions&amount=7500`
        );
      }
    },
  },

  computed: {
    ...mapGetters([
      "allCategory",
      "allLoadingCurd",
      "allAssets",
      "allSubcategory",
      "allLoadingCurd",
    ]),
  },
  async created() {
    await this.fetchCategory({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
    });

    await this.fetchSubCategory({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
    });

    await this.getAssets({
      title: "Certifications",
      connectedTo: "6660758d7357c314d65f91fa",
    });

    this.certifications = this.allAssets;
    await this.getSingleTrainingFront('subscriptions');
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: 30px;
  font-weight: 800;
  line-height: 3;
}
.boxinside {
  padding: 50px 0;
}
.about3col {
  overflow: hidden;
  box-shadow: 0px 1px 10px 1px rgb(236, 236, 236);
  min-height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  position: relative;

  .overlay {
    min-height: 300px;
    width: 100%;
    transition: 0.3s;
    &:hover {
      opacity: 0.5;
      transition: 0.3s;
    }
  }

  .category-list {
    position: absolute;
    bottom: 30px;
    padding: 0 25px;

    h4 {
      color: black;
      font-size: $titlefonth4;
    }
    p {
      color: black;
      font-size: $normal_lfont;
    }
  }
}

.video-list {
  min-height: 270px;
  background-size: cover;
  background-position: center;

  overflow: hidden;
  .videoImage {
    height: 280px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.slider {
  background: #efefef;
  .belowfold {
    padding: 0 0 30px 0;

    .logoscompany {
      column-gap: 20px;

      .companyimg {
        padding: 10px;
        border: 1px solid #cdcdcd;
        border-radius: 10px;
        object-fit: contain;
        width: 95%;
        background: #fff;
        height: 130px;
      }
    }

    ul {
      justify-content: space-between;
      display: flex;
      width: 100%;
      padding: 20px 0;
    }

    li {
      padding: 10px 15px;
      border: 1px solid red;
      border-radius: 5px;
    }
  }
}
.btn-filter {
  background: $tagcolor !important;
  width: 100%;
  color: $white;
  border-radius: 50px;
}
.video-section {
  padding: 50px 0;
  .video-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

</style>
